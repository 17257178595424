<template>
  <div  class="wrap">
    <div class="box-con">
      <!-- <area-tree @setQueryParams="handleQuery"></area-tree> -->
      <!-- 兼职管理 -->
      <el-form class="params-wrap select" size="small" :inline="true" :model="queryParams">
        <el-form-item>
          <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
        </el-form-item>
        <el-form-item label="类型：" style="marginLeft:50px">
          <el-select v-model="queryParams.type" @change="resetPageAndRefresh" clearable filterable placeholder="选择类型">
            <el-option label="全部" value="" ></el-option>
            <el-option label="兼职" :value="1"  ></el-option>
            <el-option label="承包" :value="2" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="fr">
          <el-button @click="isEditor=!isEditor"  v-if="OA.includes('yygl:jzgl:editor')"  :type="isEditor ? 'success' : ''">{{isEditor ? '完成' : '编辑'}}</el-button>
        </el-form-item>
        <el-form-item class="fr">
          <el-button @click="add" icon="el-icon-plus" v-if="OA.includes('yygl:jzgl:add')" >新增</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <div v-if="partTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" :data="partList" :isEditor="isEditor"  @refresh="refresh" :total="partTotal" :span='12'>
        <template slot-scope="scope">
          <div class="list" @click="detail(scope.row.id)"  >
            <div class="con-head">
              <div class="area">
                <p class="con-txt">{{scope.row.type==1?'兼职':'承包'}}</p>
                <p class="mission">类型</p>
              </div>
              <div class="area">
                <p class="con-txt txtWidth" :title="scope.row.title">{{scope.row.title?scope.row.title:"-"}}</p>
                <p class="mission">标题</p>
              </div>
              <div class="areas fr">
                <p class="con-txt tinctblue" v-if="scope.row.status==0">发布中</p>
                <p class="con-txt tinctgray" v-if="scope.row.status==1">已关闭</p>
                <p class="con-txt tinctred" v-if="scope.row.status==2">已删除</p>
                <p class="con-txt tinctred" v-if="scope.row.status==3">已删除</p>
                <p class="mission fr">状态</p>
              </div>
              <div class="area fr">
                <p class="con-txt">{{scope.row.number?scope.row.number:0}}</p>
                <p class="mission">报名人数</p>
              </div> 
              <div class="area fr">
                <p class="con-txt">￥{{scope.row.salary?scope.row.salary:"-"}}</p>
                <p class="mission" v-if="scope.row.type==1">酬劳（{{scope.row.payType == 0?'日':'月'}}结）</p>
                <p class="mission" v-if="scope.row.type==2">酬劳（完工结）</p>
              </div>
            </div>
            <div class="con-con cfx">
              <div class="time-fl fl">
                <div class="time">
                  <span class="contit">发布时间</span>
                  <span class="condel">{{scope.row.addTime?scope.row.addTime:"-"}}</span>
                </div>
                <div class="time">
                  <span class="contit">学校名称</span>
                  <span class="condel">{{scope.row.extend.schoolName?scope.row.extend.schoolName:"-"}}</span>
                </div>
              </div>
              <div class="time-fr fr">
                <div class="time">
                  <span class="contit">发布者</span>
                  <span class="condel">{{scope.row.extend.userName?scope.row.extend.userName:"-"}}</span>
                </div>
                <div class="time">
                  <span class="contit">公司名称</span>
                  <span class="condel">{{scope.row.orporateName?scope.row.orporateName:"-"}}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:control="{row}">
          <el-button v-if="row.status == 1 && OA.includes('yygl:jzgl:editor')" type="primary" @click="republish(row.id)" icon="el-icon-edit" circle></el-button>
          <el-button v-if="row.status == 1 && OA.includes('yygl:jzgl:del')" type="danger"  @click="partTimeDelete(row.id)" icon="el-icon-delete" circle></el-button>
        </template>
      </paged-list>
    </div>
    <!-- 详情 -->
    <el-drawer
      append-to-body
      title="详情"
      :visible.sync="drawer"
      size="35%"
      v-if="details"
      >
      <div class="allocationdetail">
        <div class="d-head">
          <div class="headtit" v-if="details.job">
            <div class="area">
              <p class="con-txt">{{details.job.type==1?'兼职':'承包'}}</p>
              <p class="mission">类型</p>
            </div>
            <div class="area">
              <p class="con-txt" >{{details.job.addTime?details.job.addTime:''}}</p>
              <p class="mission">发布时间</p>
            </div>
            <div class="area fr">
              <p class="con-txt tinctblue" v-if="details.job.status==0">发布中</p>
              <p class="con-txt tinctgray" v-if="details.job.status==1">已关闭</p>
              <p class="con-txt tinctred" v-if="details.job.status==2">已删除</p>
              <p class="mission">状态</p>
            </div>
          </div>
          <div class="headcon" v-if="details.user">
            <p class="contit" >分配人</p>
            <img :src="details.user.headImg?details.user.headImg:''" class="pig" alt="">
            <div class="area">
              <p class="con-txt">
                {{details.user.extend.userName?details.user.extend.userName:'-'}}
                <img :src="details.user.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px">
              </p>
              <p class="mission">{{details.user.extend.schoolName?details.user.extend.schoolName:'-'}}|{{details.user.fade?details.user.fade:'-'}}</p>
            </div>
            <div class="area">
              <p class="con-txt">{{details.user.extend.mobile?details.user.extend.mobile:'-'}}</p>
              <p class="mission">手机号码</p>
            </div>
            <div class="area">
              <p class="con-txt">{{details.user.extend.schoolName?details.user.extend.schoolName:'-'}}</p>
              <p class="mission">学校名称</p>
            </div>
          </div>
        </div>
        <div class="d-condel" v-if="details.job">
          <p class="headLine">{{details.job.type==1?'兼职':'承包'}}详情</p>
          <div class="people">
            <p class="peo-head">{{details.job.title}}</p>
            <p class="peo-con"><span class="conMoney">{{details.job.salary}}元</span><span class="con" v-if="details.job.type==1">{{details.job.payType==0?'天':'月'}}</span> <span class="con" v-if="details.job.type==2">完工结</span> </p>
            <div class="welfare"><span v-for="(item,index) in welfare" :key="index">{{item}}</span></div>
            <p class="peo-time" v-if="details.job.type==1"><span class="time">工作日期：</span><span class="time-con">{{details.job.startTime}} ～ {{details.job.endTime}}</span></p>
            <p class="peo-time" v-if="details.job.type==1"><span class="time">工作时间：</span><span class="time-con">{{details.job.workStartTime}}-{{details.job.workEndTime}}</span></p>
            <p class="peo-time" v-if="details.job.type==1"><span class="time">公司名称：</span><span class="time-con">{{details.job.orporateName}}</span></p>
            <p class="peo-time"><span class="time">工作地址：</span><span class="time-con">{{details.job.address}}</span> <el-button class="fr" @click="cVisible = true">查看地址</el-button></p>
          </div>
        </div>
        <div class="d-conjob">
          <p class="jobdel">岗位详情</p>
          <p class="jobstate" v-if="details.job">{{details.job.content?details.job.content:'-'}}</p>
          <img v-for="(item,index) in pics" :key="index" :src="item" alt="">
        </div>
        <div class="d-apply">
          <p class="applylist"><span class="list">报名列表</span><span class="listdel">{{applyListTotal}}人已报名</span></p>
          <ul class="headUrl cfx" v-if="headImg.length>0">
            <li v-for="(item,index) in headImg" :key="index" :style="{zIndex:headImg.length-index}">
              <img :src="item" width="100%" height="100%">
            </li>
          </ul>
        </div>
        <div class="d-allComment" v-if="comment>0">
          <p class="comment"><span class="list">全部评论</span><span class="listdel">{{commentTotal}}条</span></p>
          <div class="commentCon" v-if="comment.length>0">
            <div class="person" v-for="(item,index) in comment" :key="index">
              <img class="head" :src="item.tappUser.headImg?item.tappUser.headImg:''" alt="">
              <div class="nameinfo">
                <span class="name">{{item.tappUser.extend.userName?item.tappUser.extend.userName:''}}
                  <img :src="item.tappUser.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px;line-height:20px;">
                </span>
                <p class="perCom">{{item.content?item.content:''}}</p>
              </div>
              <p class="fr perTime">{{item.addTime?item.content:''}}</p>
            </div>
          </div>
        </div>
        <div class="d-btn" v-if="details.job">
          <el-button :class="active==1?'active':''" @click="republish(details.job.id)" v-if="details.job.status==1">重新发布</el-button>
          <el-button :class="active==1?'active':''" @click="close" v-if="details.job.status==0">关闭</el-button>
          <el-button :class="active==2?'active':''" @click="applyList">报名列表</el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 报名列表 -->
    <el-drawer
      append-to-body
      title="报名列表"
      :visible.sync="drawerApply"
      size="35%"
      >
     <div class="applyDetail">
       <el-form class="search" size="small" :inline="true" :model="queryParams">
        <el-form-item>
          <el-input v-model="applyListSear.userName" clearable placeholder="请输入名称" ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" icon="el-icon-search" @click="applyListSearch" >查询</el-button>
        </el-form-item>
        <!-- <el-form-item class="fr">
          <el-button type="success"   @click="resetPageAndRefresh" >导出Excel表格</el-button>
        </el-form-item> -->
      </el-form>
      <div class="people" v-for="(item,index) in applyListData" :key="index" >
        <div v-if="item.jobSketch">
          <div class="person">
            <img class="head" :src="item.tappUser.headImg?item.tappUser.headImg:''" alt="">
            <div class="nameinfo">
              <span class="name">{{item.tappUser.extend.userName?item.tappUser.extend.userName:'-'}} 
                <img :src="item.tappUser.sex==1?require('@/assets/images/he/operate/man.svg'):require('@/assets/images/he/operate/woman.svg')" alt="" style="margin-left:10px;line-height:20px;">
              </span>
              <p class="fr perTime"><span>报名时间：</span>
                <span class="apptime">{{item.time?item.time:'-'}}</span>
              </p>
              <p class="perCom">{{item.tappUser.extend.schoolName?item.tappUser.extend.schoolName:'-'}}｜{{item.tappUser.fade?item.tappUser.fade:'-'}}｜{{item.tappUser.major?item.tappUser.major:'-'}}</p>
            </div>
          </div>
          <p class="peoCon" >{{item.jobSketch.content?item.jobSketch.content:'-'}}</p>
          <div class="contact">
            <span class="tactbox">
              <img class="tactimg" src="../../assets/images/operation/phone.svg" alt="">
              <span class="tactcon">{{item.jobSketch.phone?item.jobSketch.phone:'-'}}</span> 
            </span>
            <span class="tactbox">
              <img class="tactimg" src="../../assets/images/operation/qq.svg" alt="">
              <span class="tactcon">{{item.jobSketch.qq?item.jobSketch.qq:'-'}}</span> 
            </span>
            <span class="tactbox">
              <img class="tactimg" src="../../assets/images/operation/weChat.svg" alt="">
              <span class="tactcon">{{item.jobSketch.wx?item.jobSketch.wx:'-'}}</span> 
            </span>
          </div>
        </div>
      </div>
     </div>
    </el-drawer>
    <!-- 地图 -->
    <el-dialog title="经纬度选择" :visible.sync="cVisible" fullscreen :close-on-click-modal="false">
      <div class="map-container" v-if="cVisible">
        <maps :lng="parseFloat(details.job.longitude)" :lat="parseFloat(details.job.latitude)" @finish="setLngLat"></maps>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedList from "@/components/common/PagedList";
import pageMixin from "@/mixins/pageMixin";
import Maps from '@/components/common/Maps';
import AreaTree from "@/components/common/AreaTree";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'partTime',
  components: {
    PagedList,
    AreaTree,
    Maps
  },
  mixins: [pageMixin],
  data() {
    return {
      cVisible:false,
      active: 1,
      drawer: false,
      drawerApply: false,
      statusOptions: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },
      applyListSear: {
        id:'',
        userName:'',
      },
      applyId:'',
      applyListData:[],
      details:{},
      welfare:[],
      pics:[],
      headImg:[],
      comment:[],
      commentTotal:0,
      applyListTotal:0,
      
      pageApi:'getPagesList',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState('partTime',['partList','partTotal'])
  },
  methods: {
    ...mapActions('partTime',["getPagesList","jobGetDetail","jobEdit","jobDelete","jobApply","getSearchList","jobComment","jobLoad"]),
    setLngLat(val){
      this.$message({
        message: '无法改变位置，只能进行查看',
        type: 'warning'
      });
      this.cVisible = false
    },
    // 新增
    add() {
      this.$router.push({
        path:'/newPart'
      })
    },
    // 详情
    async detail(id) {
      this.applyId = id
      this.drawer = true
      const res = await this.jobGetDetail({id})
      this.details = res
      if(this.details.job.welfare) {
        this.welfare = this.details.job.welfare.split(',')
      }
      if(this.details.job.pics) {
        this.pics = this.details.job.pics.split(',')
      }
      const rex = await this.jobApply({id})
      this.applyListData = rex.list
      this.applyListTotal = rex.total
      if(rex.list[0]) {
        if(rex.list[0].tappUser) {
          // this.headImg = rex.list.map(v=>v.tappUser.headImg)
        }
      }
      const red = await this.jobComment({id})
      this.comment = red.list
      this.commentTotal = red.total
      console.log(red)
    },
    //删除
    async partTimeDelete(id){
      let obj = {
        id: id,
        status: 2
      }
      await this.jobEdit(obj)
      this.refresh()
    },
    // 重新发布
    republish(id) {
      this.active = 1
      this.$router.push({
        path:'/newPart',
        query: {
          edit: true,
          id:id
        }
      })
      this.isEditor = false
      this.drawer = false
    },
    async close() {
      this.drawer = false
      let obj = {
        id: this.applyId,
        status: 1
      }
      await this.jobEdit(obj)
      this.refresh()
    },
    // 打开报名列表
    applyList() {
      this.active = 2
      this.drawerApply = true
    },
    async applyListSearch() {
      this.applyListSear = {
        id: this.applyId,
        userName: this.applyListSear.userName 
      }
      this.applyListData = await this.getSearchList(this.applyListSear)
    },
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    }
  },
  activated(){
    this.refresh()
  },
  async mounted() {
    this.refresh()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.wrap{
  width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff;padding: 20px;
    .select{
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    .list{
      .con-head{border-bottom:1px solid $c-border;padding-bottom:15px;
        .area,.areas{display: inline-block; padding-right: 16px;
          .con-txt{ font-size: 20px;color: $c-2;line-height: 20px; font-weight: bold;font-family: PingFangSC-Regular, PingFang SC;}
          .txtWidth{ width: 200px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
          .mission{ font-size: 14px; color: $c-9;line-height: 14px;padding-top: 15px;font-family: PingFangSC-Regular, PingFang SC;}
        }
        .areas{margin-right:0;}
      }
      .con-con{
        .time-fl,.time-fr{width: 50%;display: inline-block;}
        .time{ display: inline-block; font-size: 14px;padding-top:15px;
          .contit{color: $c-9;padding-right: 10px;}
          .condel{color: $c-2; width: 200px; display: inline-block;}
        }
      }
    }
  }
}
/deep/#el-drawer__title{ background:$c-title; padding: 10px;color: $c-white;}
/deep/.el-drawer__header{ margin-bottom: 0px;}
.allocationdetail{ width: 100%;height: 100%; background: #fff; padding: 20px;
  .d-head{ color: $c-9; font-size: 14px; border-bottom: 1px solid $c-border;
    .headtit { color: $c-2; font-size: 18px; line-height: 18px;background: $c-border; padding: 20px;
      .area{display: inline-block; margin-right: 10px;
        .con-txt{ font-size: 20px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 20px;}
        .mission{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 14px;margin-top: 15px;}
      }
    }
    .headcon{
      .contit{ color: $c-2; padding: 20px 0; font-size: 16px; font-weight: bold;}
      .pig{ width: 44px; height: 44px; display: inline-block;margin-right: 16px; border-radius: 50%;}
      .area{display: inline-block; margin-right: 16px;height:50px;
        .con-txt{ font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600;color: #222;line-height: 14px;}
        .mission{ font-size: 12px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #999;line-height: 12px;margin-top: 15px;}
      }
    }
  }
  .d-condel{padding-top:20px;
    .headLine{ font-size: 16px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: bolder;color: $c-2;line-height: 16px; }
    .people{ overflow: hidden;
      .peo-head{ font-size: 18px; font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600; color: $c-2;line-height: 20px; margin: 20px 0;}
      .peo-con{ margin-bottom: 20px;
        .conMoney{ font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: $c-danger;line-height: 20px;margin-right: 20px; }
        .con{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-9;line-height: 14px; }
      }
      .welfare{
        span{ border-radius: 4px; margin-bottom: 10px; display: inline-block; border: 1px solid $c-border;padding: 6px 10px; margin-right: 10px;}
      }
      .peo-time{margin-top: 20px;
        .time{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-9;line-height: 14px;}
        .time-con{font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-2;line-height: 14px;
        }
      }
    }
  }
  .d-conjob{ margin-top: 20px;padding-bottom:20px;
    .jobdel{ font-size: 20px; padding-top:20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: $c-2;line-height: 20px; }
    .jobstate{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-2;line-height: 20px; margin-top: 15px;margin-bottom: 20px;}
    img{ width: 150px;height: 150px; background: skyblue;margin-right: 10px; border-radius: 4px;}
  }
  .d-apply{ margin-top: 20px;
    .applylist{
      .list{font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: $c-2;line-height: 20px; margin-right: 20px;}
      .listdel{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-9;line-height: 14px; }
    }
    .headUrl{ margin:20px 5px;
      li { position:relative; float:left; width:50px; height:50px; margin:0 -5px; border:#fff 5px solid; border-radius: 50%; overflow:hidden; }
    }
  }
  .d-allComment{
    .comment{
      .list{font-size: 20px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: $c-2;line-height: 20px; margin-right: 20px;}
      .listdel{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-9;line-height: 14px; }
    }
    .commentCon{ height: 300px; overflow-y:scroll;margin-top: 20px; background: #F5F7F9;padding: 20px;
      .person{ margin-bottom:20px;
        .head{ width: 50px; height: 50px;border-radius: 50%; display: inline-block;}
        .nameinfo{ width: 205px;display: inline-block;margin-left:20px;;
          .name{ color: $c-success;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 14px; display: inline-block;margin-bottom: 10px;}
          .perCom{ font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-2;line-height: 20px; }
        }
        .perTime{color: $c-9;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 20px;}
      }
    }
  }
  .d-btn{ height: 158px; width: 100%;text-align: center;padding-top:40px;
    .el-button{ width: 140px;height: 44px; }
    .active{ background: $c-success;color: $c-white !important; }
  }
}
.applyDetail{ padding: 20px;
  .search{ background: #fff;  border-radius: 4px; }
  .people{ width: 100%; min-height: 240px; background: #F6F6F6;border-radius: 6px; padding: 20px;margin-bottom:20px;
    .person{ margin-bottom:15px;
      .head{ width: 50px; height: 50px;border-radius: 50%; display: inline-block;}
      .nameinfo{ width: 350px;display: inline-block;margin-left:20px;;
        .name{ font-size: 14px; font-family: PingFangSC-Semibold, PingFang SC; font-weight: 600; color: $c-2;line-height: 14px; display: inline-block; margin-bottom: 5px; }
        .perCom{ font-size: 12px; font-family: PingFangSC-Regular, PingFang SC;font-weight: 400; color: #8E8E8E;line-height: 20px; }
        .perTime{ width: 180px; color: $c-9;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;line-height: 26px;
          .apptime{color: $c-2;}
        }
      }
       
    }
    .peoCon{ font-size: 14px; font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: $c-2;line-height: 20px; }
    .contact{margin-top:20px;
      .tactbox{width: 30%; height: 44px;   line-height: 44px;background: $c-white;display: inline-block;margin-right: 3%;  position: relative; border-radius: 5px; border: 1px solid $c-border;
        .tactimg{ position: absolute; left: 8px;top: 13px; }
        .tactcon{ margin-left: 30px; font-size: 14px;}
      } 
    }
  }
}

</style>